import get from 'lodash.get';

export const patientPageTransform = (query) =>  {
  const queryData = query.nodeQuery;

  const data = get(queryData, 'entities', []).map(entity => ({
    title: get(entity, 'entityLabel', ''),
    url: get(entity, 'path.alias', ''),
    uuid: get(entity, 'uuid', ''),
    heroBanner: {
      image: {
        src: get(entity, 'fieldBannerTop.entity.fieldBannerImage.url', ''),
        alt: get(entity, 'fieldBannerTop.entity.fieldBannerImage.alt', ''),
        title: get(entity, 'fieldBannerTop.entity.fieldBannerImage.title', ''),
      },
      imageMobile: {
        src: get(entity, 'fieldBannerTop.entity.fieldBannerImageMobile.url', ''),
        alt: get(entity, 'fieldBannerTop.entity.fieldBannerImageMobile.alt', ''),
        title: get(entity, 'fieldBannerTop.entity.fieldBannerImageMobile.title', ''),
      },
      text: get(entity, 'fieldBannerTop.entity.fieldBannerText.value', ''),
    },
    startText: get(entity, 'fieldContent', []).map(item => get(item, 'entity.fieldLongText.value', '')),
    sections: get(entity, 'fieldSection', []).map(section => ({
      uuid: get(section, 'entity.uuid', ''),
      title: get(section, 'entity.fieldSubmenuName', ''),
      components: get(section, 'entity.fieldLayout', []).map(component => get(component, 'entity', null)),
      id: get(section, 'entity.fieldAnchorId') || get(section, 'entity.fieldSubmenuName', ''),
    })),
    metaData: {
      title: get(entity, 'fieldMetaTitle', ''),
      description: get(entity, 'fieldMetatags.entity.fieldDescription', ''),
      keywords: get(entity, 'fieldMetatags.entity.fieldKeywords', ''),
      hreflang: get(entity, 'fieldHreflang', []).map(item => ({
        code: get(item, 'entity.fieldLang'),
        url: get(item, 'entity.fieldHref.url.path')
      })),
    },
  }));

  return data;
}